<template>
  <v-list-item>
    <v-list-item-content>
      <v-img
        :src="src"
        aspect-ratio="1"
        max-height="100"
        contain
        alt="client logo"
      />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'pibot-client-logo',
  props: {
    src: {
      type: String,
      default: require('./IRM_SPD_logo.svg')
    }
  }
}
</script>

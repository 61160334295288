<template>
  <v-menu rigth bottom offset-y z-index="99999999">
    <template #activator="{ on }">
      <v-btn text style="text-transform: none;" v-on="on">
        {{ $me.username }}
        <v-icon right>person</v-icon>
      </v-btn>
    </template>

    <v-list dense>
      <template v-for="item in listItems">
        <div :key="item.title">
          <v-divider v-if="item.seperator"></v-divider>
          <v-list-item link :to="item.to" :disabled="item.disabled" exact>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  data () {
    return {
      listItems: [
        // { title: 'Account', to: '/me', disabled: true },
        { seperator: false, title: 'Settings', to: '/settings' },
        { seperator: false, title: 'Sign out', to: '/login' }
      ]
    }
  }
}
</script>

<template>
  <div class="pibot-drawer-list">
    <template v-for="item in items">
      <v-list-group
        v-if="item.children && item.children.length"
        :key="item.label"
        v-model="item.active"
        :prepend-icon="item.icon"
        no-action
        :sub-group="sub"
        :class="{'pibot-drawer-sub-list': sub}"
        color="primary darken-1"
      >
        <!-- Collapse handler -->
        <template #activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.label" />
            <v-list-item-subtitle v-if="item.subtext" v-text="item.subtext" />
          </v-list-item-content>
        </template>

        <!-- Children -->
        <template v-for="subItem in item.children">
          <v-list-item
            :key="subItem.label"
            link
            :exact="subItem.exact"
            :to="subItem.path"
            color="primary darken-1"
          >
            <v-list-item-content>
              <v-list-item-title v-text="`${subItem.label}`"></v-list-item-title>
              <v-list-item-subtitle v-if="subItem.subtext" v-text="subItem.subtext" />
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon v-text="subItem.icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>

          <!-- Children's children -->
          <pibot-drawer-list :key="`${subItem.label}-sub`" :items="subItem.children" :sub="true" />
        </template>
      </v-list-group>

      <!-- Single list item -->
      <v-list-item v-else link :key="item.label" :to="item.path || item.ns" :exact="!!item.exact" active-class="primary--text text--darken-1" color="primary darken-1">
        <v-list-item-icon>
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="item.label" />
          <v-list-item-subtitle v-if="item.subtext" v-text="item.subtext" />
        </v-list-item-content>
      </v-list-item>
    </template>
  </div>
</template>

<script>
export default {
  name: 'pibot-drawer-list',
  props: ['items', 'sub']
}
</script>

<style>
.pibot-drawer-sub-list .v-list-group__items {
  max-width: 100%;
}
</style>

<template>
  <v-app id="inspire">
    <pibot-token-refresh v-if="tokenRefresh > 0" style="position: absolute" />
    <!-- Navigation drawer -->
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.mdAndUp"
      fixed
      app
      :width="288"
    >
      <v-list dense nav>
        <!-- Logo -->
        <pibot-client-logo />

        <!-- Nav items -->
        <pibot-drawer-list :items="drawerItems" />
      </v-list>

      <!-- Incident notification button-->
      <template v-slot:append v-if="isDemo">
        <div class="pa-5">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn block x-large color="warning darken-1" v-on="on" @click="triggerNotification">
                New Demo <br/> Notification
              </v-btn>
            </template>
            <span>This button is for demo purposes only</span>
          </v-tooltip>
        </div>
      </template>

    </v-navigation-drawer>

    <!-- Toolbar -->
    <v-app-bar color="primary darken-1" dense dark clipped-left fixed app flat>
      <v-app-bar-nav-icon aria-label="Menu toggle" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>{{appTitle}}</v-toolbar-title>

      <v-spacer></v-spacer>

      <pibot-user-btn-menu />
    </v-app-bar>

    <!-- Content -->
    <v-main>
      <v-container fluid fill-height ma-0 pa-0>
        <router-view />
      </v-container>
    </v-main>

    <!-- Incident notification snackbar -->
    <v-overlay :value="notification">
      <v-snackbar id="notification" v-bind:class="{ shake: notification }" v-model="notification" centered vertical multi-line timeout="-1">
        We've received a message from transport ship <b> {{ callsign }} </b> that a container fell into the water at coordinates <b> {{ coordinatesText }} </b>
        at a distance of <b> {{ distanceToPipeline }} m </b> to <b> Demo Asset 3 </b>. {{ endOfSnackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" color="pink" class="pr-5" :loading="loading" @click="dismiss()">Dismiss</v-btn>
          <v-btn text v-bind="attrs" color="pink" class="pl-5" :loading="loading" @click="goToEprs()">Go to overview page</v-btn>
        </template>
      </v-snackbar>
    </v-overlay>

  </v-app>
</template>

<script>
import UserBtnMenu from '@/components/buttons/UserBtnMenu'
import ClientLogo from '@/components/general/clientLogo/ClientLogo'
import DrawerList from '@/components/navigation/DrawerList'
import TokenRefresh from '@/components/dialogs/TokenRefresh'
import { mapState, mapActions } from 'vuex'
import * as turf from '@turf/turf'

export default {
  components: {
    'pibot-user-btn-menu': UserBtnMenu,
    'pibot-client-logo': ClientLogo,
    'pibot-drawer-list': DrawerList,
    'pibot-token-refresh': TokenRefresh
  },
  data: () => ({
    appTitle: process.env.VUE_APP_TITLE,
    dialog: false,
    drawer: null,
    logoSrc: require('@/assets/ClientLogo.jpg'),
    notification: false,
    callsign: undefined,
    coordinatesText: undefined,
    coordinates: undefined,
    distance: undefined,
    distanceToPipeline: 0,
    loading: false,
    demoLine: turf.lineString(require('@/assets/datasamples/demo-asset-3.json'))
  }),
  methods: {
    ...mapActions({
      create: 'eprs/socket_createIncident'
    }),
    dismiss () {
      this.notification = false
      if (this.$router.history.current.fullPath === '/eprs/') this.$router.go()
    },
    goToEprs () {
      this.notification = false
      if (this.$router.history.current.fullPath !== '/eprs/') this.$router.push('/eprs/')
      else this.$router.go()
    },
    triggerNotification () {
      this.generateCallsign()
      this.generateCoordinates()
      this.loading = true
      this.notification = true
      this.create({
        absolute_distance: this.distance,
        distance_to_pipeline: this.distanceToPipeline,
        coordinates: this.coordinates,
        add_geojson: true,
        date_of_notification: Date.now(),
        date_of_occurance: Date.now(),
        incident_name: 'Dropped object',
        incident_description: 'Container fell from ' + this.callsign,
        pipeline_name: 'Demo asset 3',
        escalated: this.distanceToPipeline <= 25
      })
        .finally(() => {
          this.loading = false
        })
    },
    generateCallsign () {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      let callsign = ''
      for (let i = 0; i < 4; i++) {
        callsign += characters.charAt(Math.floor(Math.random() * 26))
      }
      if (Math.floor(Math.random() * 4) === 0) {
        const index = Math.floor(Math.random() * 4)
        callsign = callsign.substring(0, index) + Math.floor(Math.random() * 10) + callsign.substring(index + 1)
      }
      this.callsign = callsign
    },
    generateCoordinates () {
      const lineSegment = turf.lineSliceAlong(this.demoLine, 10000, 215000, { units: 'meters' })
      const nearBuffer = turf.buffer(lineSegment, 5, { units: 'meters' })
      const farBuffer = turf.buffer(lineSegment, 25, { units: 'meters' })
      const bbox = turf.bbox(farBuffer)
      const lonMin = bbox[0]
      const latMin = bbox[1]
      const lonMax = bbox[2]
      const latMax = bbox[3]

      // get withinBounds from local storage, cause page will refresh
      let withinBounds = localStorage.getItem('withinBounds')
      if (withinBounds === null || withinBounds === 'true' || withinBounds === true) withinBounds = true
      else withinBounds = false
      const onTop = !(!withinBounds || Math.random() < 0.2) // if withinBounds, then 80% chance
      let lon, lat
      if (onTop) {
        const point = turf.along(lineSegment, Math.round(Math.random() * 226600 / 1000), { units: 'kilometers' })
        lon = point.geometry.coordinates[0]
        lat = point.geometry.coordinates[1]
      } else {
        while (true) {
          lon = lonMin + (Math.random() * (lonMax - lonMin))
          lat = latMin + (Math.random() * (latMax - latMin))
          if (withinBounds && turf.booleanPointInPolygon(turf.point([lon, lat]), nearBuffer)) break
          if (!withinBounds && !turf.booleanPointInPolygon(turf.point([lon, lat]), farBuffer)) break
        }
      }
      this.coordinates = [parseFloat(lon.toFixed(3)), parseFloat(lat.toFixed(3))]
      this.coordinatesText = '(' + parseFloat(lon.toFixed(3)) + ', ' + parseFloat(lat.toFixed(3)) + ')'
      const nearestPoint = turf.nearestPointOnLine(this.demoLine, turf.point([lon, lat]), { units: 'meters' })
      this.distance = Math.round(nearestPoint.properties.location)
      this.distanceToPipeline = Math.round(nearestPoint.properties.dist)
      localStorage.setItem('withinBounds', !withinBounds)
    }
  },
  computed: {
    ...mapState({
      tokenRefresh: state => state.account.askTokenRefresh,
      drawerItems: state => state.app.drawerItems,
      isDemo: state => state.eprs.isDemo,
      map: state => state.mapbox.map
    }),
    endOfSnackbarText () {
      return this.distanceToPipeline <= 25
        ? 'Because of its proximity to Demo Asset 3 this notification has been escalated to an incident and was added to the EPRS overview page.'
        : 'This notification has been added to the EPRS overview page.'
    }
  }
}
</script>

<style scoped>
.shake {
  animation: shake 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes shake {
  10%, 50%, 90% { transform: translate3d(-6px, 0, 0); }
  20%, 60% { transform: translate3d(6px, 0, 0); }
  30%, 70% { transform: translate3d(0, 6px, 0); }
  40%, 80% { transform: translate3d(0, -6px, 0); }
  100% { transform: translate3d(0, 0, 0); }
}
</style>

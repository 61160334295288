<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <v-card>
      <v-card-title class="text-h5">Session about to expire</v-card-title>
      <v-card-text>
        <p>Your session expires in <span class="primary--text text--darken-1">{{timeLeft}}</span>.</p>
        <p>Would you like to extend your session?</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click="clearTimerInterval" to="/login">Sign out</v-btn>
        <v-btn color="primary darken-1" @click="refresh">Extend</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'pibot-token-refresh',
  data () {
    return {
      dialog: true,
      interval: null,
      timeLeft: 0
    }
  },
  computed: {
    ...mapState({
      tokenRefresh: state => state.account.askTokenRefresh,
      timeBeforeExpire: state => state.account.timeBeforeExpire
    }),
    later () {
      return moment().add(...this.timeBeforeExpire)
    },
    msBeforeExpire () {
      const now = moment()
      const later = moment(now).add(...this.timeBeforeExpire)
      return later.diff(now)
    }
  },
  methods: {
    ...mapActions({
      refreshToken: 'account/socket_refreshToken'
    }),
    clearTimerInterval () {
      clearInterval(this.interval)
    },
    refresh () {
      this.refreshToken()
        .then(() => {
          this.dialog = false
          this.clearTimerInterval()
        })
        .catch(() => {
          this.$router.push('/login')
        })
    }
  },
  created () {
    if (this.interval) this.clearTimerInterval()

    let numSeconds = 0
    this.interval = setInterval(() => {
      if (numSeconds >= (this.msBeforeExpire / 1000)) {
        clearInterval(this.interval)
        this.$router.push({
          path: '/login',
          query: {
            session_expired: 1
          }
        })
      }

      this.timeLeft = moment.utc(moment(this.later).diff(moment())).format('mm:ss')

      numSeconds++
    }, 1000)
  }
}
</script>
